import { queryOptions, type QueryKey } from "@tanstack/react-query";
import type { VmImage } from "@warrenio/api-spec/spec.oats.gen";
import { mapFrom } from "@warrenio/utils/collections/maps";
import { atom } from "jotai/vanilla";
import { mapQueryData } from "../../utils/query/mergeQueries.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import type { ApiClient } from "../api/apiClient";
import { getResponseData } from "../api/apiClient";
import { getAvailableImages } from "./os/os.ts";

export type Response = VmImage[];

export const queryKey: QueryKey = ["vm_images"];

export function getQuery(client: ApiClient) {
    return queryOptions({
        queryKey,
        queryFn: async ({ signal }): Promise<Response> => {
            return getResponseData(await client.GET("/config/vm_images", { signal }));
        },
    });
}

export const allImagesQueryAtom = atomFromStandardQueryOptions(getQuery);

export const availableImagesAtom = atom((get) => mapQueryData(get(allImagesQueryAtom), getAvailableImages));

/** Map from OS name to image */
export const imagesByOsNameAtom = atom((get) =>
    mapQueryData(get(allImagesQueryAtom), (data) => mapFrom(data, (image) => image.os_name)),
);

export function useSuspense() {
    return useSuspenseQueryAtom(availableImagesAtom);
}
